import React, { useContext, useEffect } from 'react';
import useSWR from 'swr';
import get from 'lodash/get';
import cookie from 'js-cookie';

import axios from '../utils/ajax';
import appContext from '../Context/appContext';
import { DEFAULT_GIFTCARD_LOCATION, DEFAULT_GIFTCARD_ZIPCODE, DEFAULT_ZIPCODE, DEFAULT_ZONE_ID } from '../constants/default';
import StorageService from '../services/storageService';
import CustomOunceTypes from '../constants/customOunceConsts';
import { splitIntoDateTimeComponents } from '../helpers/timeHelpers';
import * as COOKIE_VARIABLES from '../constants/cookieVariables';
import { useDispatch, useSelector } from 'react-redux';
import { updateAsapDriverID, updateLocationId } from '../redux/slices/userSlice';

const config = {
  giftcard: !!(process.browser && window.location.search.includes('giftcard'))
};

const fetcher = url =>
  axios({ baseURL: process.env.NEXT_PUBLIC_SECURE_EC2_URL, url, method: 'GET', ...config }).then(res => res.data);

const localFetcher = url => axios({ url, method: 'GET', ...config }).then(res => res.data);

const noErrorfetcher = url => {
  return axios({ baseURL: process.env.NEXT_PUBLIC_SECURE_EC2_URL, url, method: 'GET', hideError: true, ...config }).then(
    res => res.data
  );
};

const freightFetcher = (url, latitude, longitude) => {
  const params = {
    latitude,
    longitude
  };
  return axios({ baseURL: process.env.NEXT_PUBLIC_SECURE_EC2_URL, url, params, method: 'GET' }).then(res => res.data);
};

export const useDeliveryDetails = (initialDeliveryAddressDetails, revalidateOnMount = false) => {
  // const {
  //   dispatch,
  //   state: {
  //     // location,
  //     // user: {
  //     //   deliveryDetails: { postcode = DEFAULT_ZIPCODE },
  //     //   shouldFetchProduct
  //     // }
  //   }
  // } = useContext(appContext);
  const dispatchRedux = useDispatch()
  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE },
    shouldFetchProduct,
    location
  } = useSelector(state => state.user)

  const { data, error, mutate } = useSWR(
    (postcode && shouldFetchProduct) || (!initialDeliveryAddressDetails && postcode)
      ? `/pre_fetch/delivery_details/${postcode}`
      : null,
    fetcher,
    {
      revalidateOnMount: true,
      revalidateOnFocus: true
    }
  );

  const deliveryResponse = !data && initialDeliveryAddressDetails ? initialDeliveryAddressDetails : data || null;
  const deliveryDays = get(deliveryResponse, 'scheduleDetails', []);
  const asapEnabled = get(deliveryResponse, 'asapEnabled', undefined);
  const scheduleEnabled = get(deliveryResponse, 'scheduleEnabled', undefined);
  const scheduleDeliveryMessage = get(deliveryResponse, 'scheduleDeliveryMessage', undefined);
  const asapTimer = get(deliveryResponse, 'asapTimer', {});
  const scheduleTimer = get(deliveryResponse, 'scheduleTimer', {});
  const todayScheduleTimer = get(deliveryResponse, 'todayScheduleTimer', {}); // timer till cutofftime of last slot for today
  const noScheduleSlotsMessage = get(deliveryResponse, 'noScheduleSlotsMessage', '');
  const asapStartTime = get(deliveryResponse, 'asapStartTime', '09:00:00');
  const asapReOpensAt = get(deliveryResponse, 'asapReOpensAt', undefined);
  const customText = get(deliveryResponse, 'customText', undefined);

  const customTextAsap = get(deliveryResponse, 'customTextAsap', undefined);
  const customTextScheduleTimeSpecificText = get(deliveryResponse, 'customTextScheduleTimeSpecificText', undefined);
  const customTextAsapTimeSpecificText = get(deliveryResponse, 'customTextAsapTimeSpecificText', undefined);
  const customTextSchedule = get(deliveryResponse, 'customTextSchedule', undefined);

  const deliverFee = get(deliveryResponse, 'deliverFee', undefined);
  const minOrderAmount = get(deliveryResponse, 'minOrderAmount', undefined);
  const freightLimit = get(deliveryResponse, 'freightLimit', undefined);
  const hasGeofenceInventory = get(deliveryResponse, 'hasGeofenceInventory', false);
  const locationId = get(deliveryResponse, 'locationId', false);
  const lastSlotCutOff = get(deliveryResponse, 'lastSlotCutOff', false);
  const isAsapOpen = get(deliveryResponse, 'is_asap_open', 0);
  const unified_menu = get(deliveryResponse, 'unified_menu', undefined);
  const asapEndTime = get(deliveryResponse, 'asapEndTime', '22:00:00');
  const showRecommendedProducts = get(deliveryResponse, 'show_recommended_products', true);

  const ASAPDriverId = get(deliveryResponse, 'asap_driver_id', null);
  StorageService.setItemSession("ASAP_DRIVER_ID", ASAPDriverId)
  // dispatchRedux(updateAsapDriverID(ASAPDriverId))

  const happyHours = get(deliveryResponse, 'happy_hours', {});

  const asapWindow = {
    startTime: get(deliveryResponse, 'asapStartTime', '09:00:00'),
    endTime: get(deliveryResponse, 'asapEndTime', '22:00:00'),
    displayEndTime: locationId === 1 ? '21:30:00' : get(deliveryResponse, 'asapEndTime', '22:00:00')
  };
  const priorityDeliveryConfigId = get(deliveryResponse, 'priority_delivery_config_id', undefined);
  const priorityDeliveryFee = get(deliveryResponse, 'priority_delivery_fee', undefined);
  const delayedCreditBack = get(deliveryResponse, 'delayed_credit_back', undefined);
  const deliveryThreshold = get(deliveryResponse, 'delivery_threshold', undefined);
  const avgDeliveryTime = get(deliveryResponse, 'avg_delivery_time', undefined);
  const isHexLocation = deliveryResponse?.is_hex_location || false;

  const isWalletCreditWithPurchaseApplicable = deliveryResponse?.is_wallet_with_purchase_applicable || false;

  const firstDeliveryDay = deliveryDays?.[0] || {};
  if (firstDeliveryDay?.day === 'Today') {
    // Modify earliest window of today to ensure that start time of slot
    // will be after the current time in PST, rounded to the next 30th minute
    // Will only work in PST, may show the wrong time in other timezone

    let earliestWindow = firstDeliveryDay?.deliveryWindows?.[0] || {};
    const splitSlot = earliestWindow.slot.split('- ');
    const startSlotDateObject = new Date(earliestWindow.dateTimeLocal);
    const endSlot = earliestWindow.endTime;
    const split = earliestWindow.dateTimeLocal.split(' ');
    const endSlotDateObject = new Date(`${split[0]} ${endSlot}`);

    const currentDateObject = new Date();
    if (currentDateObject > startSlotDateObject && currentDateObject < endSlotDateObject) {
      const millis = currentDateObject.getTime();
      // Round off to the next 30th minute
      const factor = 1000 * 60 * 30;
      const currentStartTime = splitIntoDateTimeComponents(
        new Date(Math.ceil(millis / factor) * factor),
        true
      ).time12;

      const updatedSlot = `${currentStartTime} - ${splitSlot[1]}`;
      earliestWindow = { ...earliestWindow, slot: updatedSlot };

      // Update deliveryDays immutably
      const updatedDeliveryWindows = [...deliveryDays[0].deliveryWindows];
      updatedDeliveryWindows[0] = { ...earliestWindow };

      const updatedDeliveryDays = [...deliveryDays];
      updatedDeliveryDays[0] = {
        ...deliveryDays[0],
        deliveryWindows: updatedDeliveryWindows
      };

      deliveryDays = updatedDeliveryDays;
    }
  }

  useEffect(() => {

    dispatchRedux(updateAsapDriverID(ASAPDriverId))
  }, [ASAPDriverId])
  React.useEffect(() => {
    if (deliveryResponse?.customerDetails) {
      if (Object.keys(deliveryResponse.customerDetails).length > 0 && deliveryResponse.customerDetails.is_employee !== null) {
        StorageService.setUserData({
          ...(StorageService.getUserData() || {}),
          customerDetails: deliveryResponse.customerDetails
        });
      }
    }
    if (deliveryResponse && location !== deliveryResponse.locationId) {
      // dispatch({ type: 'updateUserData', payload: deliveryResponse.locationId });
      dispatchRedux(updateLocationId(deliveryResponse.locationId))
    }
  }, [deliveryResponse]);

  return {
    data: {
      deliveryDays,
      asapEnabled,
      asapWindow,
      asapStartTime,
      asapTimer,
      scheduleTimer,
      todayScheduleTimer,
      asapReOpensAt,
      scheduleEnabled,
      noScheduleSlotsMessage,
      customText,
      customTextAsap,
      customTextScheduleTimeSpecificText,
      customTextAsapTimeSpecificText,
      customTextSchedule,
      unified_menu,
      deliverFee,
      minOrderAmount,
      freightLimit,
      hasGeofenceInventory,
      locationId,
      lastSlotCutOff,
      isAsapOpen,
      priorityDeliveryConfigId,
      asapEndTime,
      priorityDeliveryFee,
      delayedCreditBack,
      deliveryThreshold,
      avgDeliveryTime,
      isHexLocation,
      showRecommendedProducts,
      happyHours,
      scheduleDeliveryMessage,
      isWalletCreditWithPurchaseApplicable
    },
    isLoading: !error && !deliveryResponse,
    isError: error,
    mutate
  };
};

export const useZipCodeStatus = (initialLocationAddress = false) => {
  // const {
  //   state: {
  //     user: {
  //       shouldFetchProduct,
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE, latitude, longitude, zoneId: existing_zone_id },
  //     }
  //   }
  // } = useContext(appContext);

  const {
    shouldFetchProduct,
    deliveryDetails: { postcode = DEFAULT_ZIPCODE, latitude, longitude, zoneId: existing_zone_id },
  } = useSelector(state => state.user)

  const options = {
    revalidateOnFocus: false
  };

  const { data, error, mutate } = useSWR(
    (postcode && shouldFetchProduct) || (postcode && !initialLocationAddress) || (postcode && config.giftcard)
      ? `/address/${config.giftcard ? DEFAULT_GIFTCARD_ZIPCODE : postcode}?lat=${config.giftcard ? DEFAULT_GIFTCARD_LOCATION.lat : latitude
      }&lng=${config.giftcard ? DEFAULT_GIFTCARD_LOCATION.lng : longitude}`
      : null,
    fetcher,
    options
  );
  const locationAddress = !data ? initialLocationAddress : data;
  const validZipcode = !postcode || postcode == DEFAULT_ZIPCODE ? false : get(locationAddress, 'is_available', false);
  const locationId = get(locationAddress, 'location_id', false);
  const zoneName = get(locationAddress, 'zone_name', '');
  const zoneId = postcode == DEFAULT_ZIPCODE ? 0 : get(locationAddress, 'zone_id', DEFAULT_ZONE_ID);

  if (process.browser) {
    StorageService.setZoneLocalStorage(zoneName);
    // if (zoneId !== existing_zone_id) {
    //   StorageService.setDeliveryDetails({
    //     ...deliveryDetails,
    //     zoneId: zoneId
    //   });
    //   cookie.set(COOKIE_VARIABLES.zoneId, zoneId);
    // }
  }

  // useEffect(() => {
  //   if (zoneId !== existing_zone_id) {
  //     dispatch({ type: 'saveLocation', payload: { ...deliveryDetails, zoneId: zoneId } });
  //   }
  // }, [zoneId]);

  return {
    data: { validZipcode, locationId, zoneId, isLoading: !error && !data },
    mutate
  };
};

export const useConfig = () => {
  const { data } = useSWR(`/configuration`, fetcher);
  const configObj = get(data, 'data', {});
  return {
    data: configObj
  };
};

export const useAnnouncementBar = (initialAnnouncmentDetails = false) => {
  // const {
  //   state: {
  //     user: {
  //       shouldFetchProduct,
  //       deliveryDetails: { postcode }
  //     }
  //   }
  // } = useContext(appContext);
  const {
    shouldFetchProduct,
    deliveryDetails: { postcode },
  } = useSelector(state => state.user)
  const { data, mutate } = useSWR(
    shouldFetchProduct || !initialAnnouncmentDetails ? [`/pre_fetch/announcement_bar`, postcode] : null,
    noErrorfetcher
  );
  return {
    data: data || initialAnnouncmentDetails,
    mutate
  };
};

export const useFreightLimit = (initialFreightLimit = false) => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { latitude, longitude },
  //       shouldFetchProduct
  //     }
  //   }
  // } = useContext(appContext);
  const {
    shouldFetchProduct,
    deliveryDetails: { latitude, longitude },
  } = useSelector(state => state.user)

  const options = {
    revalidateOnFocus: false,
    revalidateOnMount: true
  };

  if (initialFreightLimit) {
    options.initialData = { initialFreightLimit, dontModify: true };
  }

  const { data, mutate } = useSWR(
    shouldFetchProduct || !initialFreightLimit ? [`/customers/freight`, latitude, longitude] : null,
    freightFetcher,
    options
  );

  const freightLimit = data && data.dontModify ? initialFreightLimit : get(data, 'data.freightLimit', ' - ');

  return {
    data: freightLimit,
    mutate
  };
};

export const useWorkingHours = () => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  //     }
  //   }
  // } = useContext(appContext);
  const {
    shouldFetchProduct,
    deliveryDetails: { postcode = DEFAULT_ZIPCODE },
  } = useSelector(state => state.user)

  const { data } = useSWR(postcode ? `/location/working_hours/${postcode}` : null, fetcher, {
    revalidateOnFocus: false
  });
  const workingHours = data;

  return {
    data: workingHours
  };
};

export const useAppVersion = () => {
  const { data } = useSWR(`/pre_fetch/version`, fetcher);
  const appVersion = Number(get(data, 'data.version', 0));

  return {
    data: appVersion
  };
};

export const getBuildID = () => {
  const { data } = useSWR(`/api/build`, localFetcher);

  return {
    data
  };
};

export function useHexAsapDeliveryDetails(locationId, changeProps, swrOptions = {}) {
  const { data, error, isValidating, mutate } = useSWR(
    locationId ? [`/pre_fetch/asap_delivery_details?location_id=${locationId}`, ...changeProps] : null,
    fetcher,
    {
      ...swrOptions
    }
  );

  // console.log('newlocationId', locationId, swrOptions);
  // console.log('newData', data);

  // const { deliveryDetails, token } = data?.result || {};

  // if (deliveryDetails && token) {
  //   StorageService.setItemSession('NEW_ASAP_DELIVERY_DETAILS', deliveryDetails);
  //   StorageService.setItemSession('NEW_ASAP_DELIVERY_TOKEN', token);
  // } else {
  //   StorageService.removeItemSession('NEW_ASAP_DELIVERY_DETAILS');
  //   StorageService.removeItemSession('NEW_ASAP_DELIVERY_TOKEN');
  // }

  return {
    data: data?.result || null,
    isLoading: !error && !data,
    isValidating,
    mutate
  };
}
